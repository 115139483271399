/** Import WebPack/Vue Components */
//import Popper from 'popper.js';
//window.Popper = Popper;
window.axios = require('axios');
//window.Vue = require('vue');
import Vue  from 'vue'
import Popper from 'popper.js';
window.Popper = Popper;
import PkDragndrop from './vue-components/pk-dragndrop.vue';
Vue.component('pk-dragndrop',PkDragndrop);
